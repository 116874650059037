import { actualSite } from "@/app/utils/utils";
import React from "react";
import { cardData } from "./BankInfo";
import BankingBanner from "./BankingBanner";
import BankingCard from "./BankingCard";

const BankingLayout: React.FC = () => {
  const { siteDefaultName } = actualSite();

  return (
    <section className="flex flex-col justify-center py-8 bg-neutral-100">
      <header className="flex justify-center items-center px-16 w-full text-center text-black leading-[120%] max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col max-w-full ">
          <h2 className="text-4xl">Serviços de conveniência ao cliente</h2>

          <p className="px-6 py-0.5 mt-5 max-md:px-5 border-b-2 border-black w-fit mx-auto">
            Os serviços mais procurados
          </p>
        </div>
      </header>
      <main className="flex flex-col items-end px-16 pt-6 max-md:pl-5 bg-neutral-100">
        <div className="flex flex-wrap gap-6 justify-center items-start mt-6 w-full max-w-[1140px] mx-auto max-md:max-w-full">
          {cardData.map((card, index) => (
            <BankingCard key={"bankingcard.layout" + index} {...card} />
          ))}
          <BankingBanner
            subtitle={`TOYOTA ${siteDefaultName.toLocaleUpperCase()}`}
            title="Consórcio"
            imageSrc="https://sablobsitestoyotaprd.blob.core.windows.net/images/Home/Servi%C3%A7os%20de%20conveni%C3%AAncia%20ao%20cliente/card_consorcio.png"
            buttonText="Saiba mais"
          />
        </div>
      </main>
    </section>
  );
};

export default BankingLayout;
