import { GrNext, GrPrevious } from "react-icons/gr";

export function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <div
      className={
        " absolute h-fit bg-gray-500 text-neutral-100 p-2 px-4 w-fit rounded-full inset-0 mt-auto ml-auto mr-2 -mb-16"
      }
      onClick={onClick}
    >
      <GrNext size={20} />
    </div>
  );
}

export function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <div
      className={
        " absolute z-20 bg-gray-500 text-neutral-100 p-2 px-4 w-fit h-fit rounded-full inset-0 mt-auto mr-auto ml-2 -mb-16"
      }
      onClick={onClick}
    >
      <GrPrevious size={20} />
    </div>
  );
}

export function CustomPaging({
  index,
  currentIndex,
}: {
  index: number;
  currentIndex: number;
}) {
  return (
    <div
      className={`w-6 h-2 cursor-pointer relative -bottom-10 ${
        currentIndex === index ? "bg-gray-500" : "bg-gray-300"
      }`}
    ></div>
  );
}
