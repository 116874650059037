export interface CarModel {
  imageSrc: string;
  logoSrc: string;
  altText: string;
  carLink: string;
}
export const carModels: CarModel[] = [
  {
    imageSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Home/Explore%20os%20modelos/card_novocorollacross.webp",
    logoSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/logo_novo_corolla-gross.svg",
    altText: "Car model 0",
    carLink: "/corolla-cross",
  },
  {
    imageSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Home/Explore%20os%20modelos/card_corolla.webp",
    logoSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Logo_corolla.svg",
    altText: "Car model 1",
    carLink: "/corolla",
  },
  {
    imageSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Home/Explore%20os%20modelos/card_hilux.webp",
    logoSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Logo_hilux.svg",
    altText: "Car model 2",
    carLink: "/hilux",
  },
  {
    imageSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Home/Explore%20os%20modelos/card_rav4.webp",
    logoSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Logo_RAV4.svg",
    altText: "Car model 3",
    carLink: "/rav4",
  },
  {
    imageSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Home/Explore%20os%20modelos/card_sw4.webp",
    logoSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Logo_SW4.svg",
    altText: "Car model 4",
    carLink: "/sw4",
  },
  {
    imageSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Home/Explore%20os%20modelos/card_yaris.webp",
    logoSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Logo_Yaris.svg",
    altText: "Car model 5",
    carLink: "/yaris",
  },
];
