import React, { useEffect, useState, useMemo } from "react";
import BannerSlide from "@/app/components/UI/BannerSlide";
import { BannerSectionProps } from "@/app/interface/components";
import styles from "@/styles/desktop/Home/BannerSection.module.scss";

const BannerSection: React.FC<BannerSectionProps> = ({
  setIsConditionsModalOpen,
  setConditionsContent,
  setChatbotOpen,
  bannerImages,
  links,
  calls,
}) => {
  const banners = useMemo(() => {
    return bannerImages;
  }, [bannerImages]);
  if (!banners || banners.length === 0) return null;
  return (
    <section className={styles.main}>
      <BannerSlide
        banners={banners!}
        calls={calls}
        links={links}
        setChatbotOpen={setChatbotOpen}
        width={1920}
        height={505}
        setIsConditionsModalOpen={setIsConditionsModalOpen}
        setConditionsContent={setConditionsContent}
      />
    </section>
  );
};

export default React.memo(BannerSection);
