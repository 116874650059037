import Image from "next/image";
import React from "react";
import ProductCard from "./productCard";

const products = [
  {
    imageSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Home/Explore%20os%20modelos%20GR/card_gr_hilux.webp",
    logoSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Logo_Hilux_GR.svg",
    altText: "Product 1",
    carLink: "/hilux?model=esportivo",
  },
  {
    imageSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Home/Explore%20os%20modelos%20GR/card_gr_corolla.webp",
    logoSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Logo_corolla.svg",
    altText: "Product 2",
    carLink: "/corolla?model=esportivo",
  },

  {
    imageSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Home/Explore%20os%20modelos%20GR/card_gr_corolla-cross.webp",
    logoSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Logo_CorollaCross_GR.svg",
    altText: "Product 4",
    carLink: "/corolla-cross?model=esportivo",
  },
];

const ProductShowcase: React.FC = () => {
  return (
    <main className="flex flex-col items-center w-full pb-28 bg-neutral-100">
      <div className="h-60 bg-black w-full">
        <header className="flex justify-center items-center p-6 w-full max-md:px-5 max-md:max-w-full">
          <Image
            width={400}
            height={400}
            loading="lazy"
            src="https://sablobsitestoyotaprd.blob.core.windows.net/images/Home/Explore%20os%20modelos%20GR/logo_toyota_gazoo_racing.svg"
            alt="Company Logo"
            className="mb-14 max-w-full aspect-[12.5] w-[377px] max-md:mb-10"
          />
        </header>
      </div>
      <div className="h-60 bg-white"></div>
      <section className="z-10 mt-20  w-full max-md:max-w-full absolute max-w-[1140px] mx-auto">
        <div className="flex gap-5 justify-center max-md:flex-col">
          {products.map((product, index) => (
            <div
              key={"productRacing" + index}
              className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full"
            >
              <ProductCard {...product} />
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

export default ProductShowcase;
