import {
  CustomPaging,
  SampleNextArrow,
  SamplePrevArrow,
} from "@/app/components/swipersComponents";
import { actualSite } from "@/app/utils/utils";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import MainServiceCardMobile from "./MainServiceCardMobile";

const MainServicesMobile: React.FC = () => {
  const { siteDefaultName } = actualSite();
  const mainServices = [
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/966283e1d46046cc2e6dcb4ebee6e10137323135f19935207d5f9cf472d3c7ce?apiKey=eac21aa640a54d679c8b4aff1d0cf286&&apiKey=eac21aa640a54d679c8b4aff1d0cf286",
      alt: "Main service image",
      heading: "O melhor serviço de reparo e manutenção",
      buttonText: "Saiba mais",
      text: " Uma equipe de profissionais altamente qualificados para honrar os nossos dois pilares: precisão nos trabalhos executados e total dedicação ao cliente em todo o atendimento.",
      bgColor: "bg-blue-900",
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: mainServices.length > 1,
    infinite: mainServices.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: mainServices.length > 1,
    autoplay: mainServices.length > 1,
    autoplaySpeed: 5000,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentIndex(newIndex);
    },
    customPaging: (index: number) => {
      return (
        <CustomPaging
          key={"pagging-MS" + index}
          index={index}
          currentIndex={currentIndex}
        />
      );
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: Math.min(3, mainServices.length),
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: Math.min(2, mainServices.length),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className=" pt-20 w-full bg-white flex">
      <div className="lg:pb-20 w-full mx-auto flex bg-white flex-col items-center">
        <h1 className="text-3xl text-black max-md:max-w-full text-center">
          Principais serviços
        </h1>
        <p className="mt-2 text-xl leading-6 border-b border-black w-fit px-5 pb-2 text-center text-black">
          Conheça todos os benefícios que os nossos serviços oferecem
        </p>
        <div className="w-full max-w-[1140px] py-8">
          {mainServices.length === 1 ? (
            <MainServiceCardMobile {...mainServices[0]} />
          ) : (
            <Slider {...settings}>
              {mainServices.map((service, i) => (
                <MainServiceCardMobile key={service.src + i} {...service} />
              ))}
            </Slider>
          )}
        </div>
      </div>
    </section>
  );
};

export default MainServicesMobile;
