import CustomLink from "@/app/components/CustomLink";
import * as React from "react";

interface SecondaryServiceProps {
  src: string;
  alt: string;
  buttonText?: string;
  link: string;
}

const SecondaryService: React.FC<SecondaryServiceProps> = ({
  src,
  alt,
  buttonText,
  link,
}) => {
  return (
    <article className="flex flex-col ml-2 w-[24%] max-md:ml-0 max-md:w-full h-80 ">
      <div
        style={{
          backgroundImage: ` url('${src}')`,
          backgroundPosition: "center top",
          backgroundSize: "cover",
        }}
        className="flex divHover flex-col-reverse text-sm h-full justify-between text-center uppercase rounded-lg text-white max-md:px-5 max-md:mt-6"
      >
        {buttonText && (
          <CustomLink
            href={link}
            className="px-4 paint-button transition-all py-3 w-1/2 mx-auto rounded-md border border-solid bg-black bg-opacity-60 border-white max-md:px-5 max-md:mt-10 mb-4"
          >
            {buttonText}
          </CustomLink>
        )}
      </div>
    </article>
  );
};

export default SecondaryService;
