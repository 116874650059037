import { actualSite } from "@/app/utils/utils";
import Image from "next/image";
import * as React from "react";

interface MainServiceProps {
  src: string;
  alt: string;
  heading: string;
  buttonText: string;
  text: string;
  bgColor: string;
  textBlack?: boolean;
}

const MainServiceCardMobile: React.FC<MainServiceProps> = ({
  src,
  alt,
  heading,
  buttonText,
  text,
  bgColor,
  textBlack = false,
}) => {
  const { siteDefaultName } = actualSite();
  return (
    <article className="flex mx-auto justify-center flex-col px-4 items-center w-full rounded-xl">
      <Image
        width={400}
        height={400}
        className="w-full relative z-[2]"
        src={src}
        alt={alt}
      />
      <div
        className={`${bgColor} h-80 sm:h-[350px] md:h-96 pt-16 -mt-12 w-full flex gap-2 flex-col p-4  ${
          textBlack ? "text-black" : "text-white"
        }`}
      >
        <p className="text-xl leading-none">{heading}</p>
        <p className="text-sm">{text}</p>
        <button className="w-full bg-white text-slate-900 rounded-lg py-1 mt-auto">
          {buttonText}
        </button>
      </div>
    </article>
  );
};

export default MainServiceCardMobile;
