import { actualSite } from "@/app/utils/utils";
import MainService from "./MainService";
import SecondaryService from "./SecondaryService";

export const MainServices = () => {
  const { siteDefaultName } = actualSite();
  const mainServices = [
    {
      src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Home/Principais%20servi%C3%A7os/card_servicos.png",
      alt: "Main service image",
      heading: "O melhor serviço de reparo e manutenção",
      buttonText: "Saiba mais",
      link: "/servicos/pos-venda",
    },
  ];

  const secondaryServices = [
    {
      src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Home/Principais%20servi%C3%A7os/card_recall_airbag.png",
      alt: "Secondary service image one",
      buttonText: "saiba mais",
      link: "https://www.toyota.com.br/meu-toyota/servicos/recall",
    },
    {
      src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Home/Principais%20servi%C3%A7os/card_blindados.png",
      alt: "Secondary service image two",
      buttonText: "saiba mais",
      link: "/servicos/blindagem",
    },
  ];
  const features = [
    { text: "PAGAMENTO", boldText: "À VISTA" },
    { text: "NÃO PRECISA", boldText: "DE TROCA" },
    { text: "SEGURANÇA", boldText: "TOTAL" },
  ];
  return (
    <section className="flex flex-col justify-center py-8 bg-neutral-100">
      <header className="flex justify-center items-center px-16 w-full text-center text-black leading-[120%] max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col max-w-full ">
          <h2 className="text-4xl">Principais serviços</h2>

          <p className="px-6 py-0.5 mt-5 max-md:px-5 border-b-2 border-black w-fit mx-auto">
            Conheça todos os benefícios que os nossos serviços oferecem
          </p>
        </div>
      </header>

      <main className=" mt-6 max-w-[1140px] mx-auto">
        <section className="flex  max-md:flex-col w-full">
          {mainServices.map((service, idx) => (
            <MainService key={service.src + "desk" + idx} {...service} />
          ))}
          {secondaryServices.map((service, idx) => (
            <SecondaryService key={service.src + "mbl" + idx} {...service} />
          ))}
        </section>
      </main>
    </section>
  );
};
