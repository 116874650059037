import React from "react";
import { useMediaQuery } from "react-responsive";
import BannerSectionDesktop from "./BannerSectionDesktop/BannerSection";
import BannerSectionMobile from "./BannerSectionMobile/BannerSection";
import { BannerSectionProps } from "@/app/interface/components";

const BannerSection: React.FC<BannerSectionProps> = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return isDesktop ? (
    <BannerSectionDesktop {...props} />
  ) : (
    <BannerSectionMobile {...props} />
  );
};

export default React.memo(BannerSection);
