import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
  CustomPaging,
  SampleNextArrow,
  SamplePrevArrow,
} from "../swipersComponents";
import CarModelCardMobile from "./CarModelCardMobile";
import { carModels } from "./CarsInfo";

const CarModelMobile: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 3.4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.25,
        },
      },
    ],
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentIndex(newIndex);
    },
    customPaging: (index: number) => {
      return (
        <CustomPaging
          key={"pagging-carModels" + index}
          index={index}
          currentIndex={currentIndex}
        />
      );
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <section className=" pt-20 w-full bg-neutral-100 flex">
      <div className="pb-20 w-full mx-auto flex flex-col items-center overflow-hidden">
        <h1 className="text-3xl  text-black  max-md:max-w-full text-center">
          Explore todos os modelos
        </h1>
        <p className="mt-2 text-xl leading-6 text-center text-black">
          Qual Toyota é perfeito para você?
        </p>
        <div className=" w-screen py-8">
          <Slider {...settings}>
            {carModels.slice(0, 6).map((model, index) => (
              <CarModelCardMobile key={model.imageSrc + index} {...model} />
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default CarModelMobile;
