import { actualSite } from "@/app/utils/utils";

const { siteDefaultName } = actualSite();
export const cardData = [
  {
    title: "",
    imageSrc:
      "https://sablobsitestoyotaprd.blob.core.windows.net/images/Home/Servi%C3%A7os%20de%20conveni%C3%AAncia%20ao%20cliente/card_ciclotoyota.png",
    imageAlt: "Banking service illustration",
    description:
      "O jeito mais simples de estar sempre de Toyota novo e na garantia.",
    buttonText: "Saiba mais",
    link: "/servicos/ciclo-toyota",
  },
];
